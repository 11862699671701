import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { Cannabinoid, Strain } from "custom-types/Strain";
import { sortTopCannabs } from "utils/sortTopCannabs";

import Button from "components/botanic/Button";
import StarRating from "components/botanic/StarRating";
import EffectIcon from "components/Icons/EffectIcon";
import IconSvg from "components/IconSvg";
import Image from "components/Image";
import TrackImpression from "components/TrackImpression";

import FeaturedStrainCardPlaceholder from "./FeaturedStrainCardPlaceholder";

type Props = {
  strain: Strain;
  effect: string;
  lineBreak: boolean;
};

const FeaturedStrainCard = ({ strain, effect, lineBreak }: Props) => {
  const roundedStrainRating =
    strain && Math.round((strain.averageRating || 0) * 10) / 10;
  const sortedTopCannabs =
    strain?.cannabinoids && sortTopCannabs(strain.cannabinoids).slice(0, 2);

  return (
    <div
      data-testid="strain-card"
      className="w-full block mt-xl lg:mt-none col md:col-1/3"
    >
      <div
        className="bg-leafly-white rounded-full mx-auto py-xs mb-lg text-xs text-center flex items-center justify-center "
        style={{ maxWidth: 260 }}
      >
        <EffectIcon width="18" height="18" effect={effect} />
        <span className="pl-xs">
          Top <span className="font-bold">{effect.toLowerCase()}</span> strain
          in your area
        </span>
      </div>

      {strain ? (
        <>
          <Link
            href={`/strains/${strain.slug}`}
            style={{ maxWidth: 208 }}
            className="block mx-auto mb-lg text-center"
            onClick={() => {
              trackEvent("Homepage Strains", "Click", "Strain");
            }}
          >
            <Image
              src={(strain.nugImage || strain.stockNugImage) as string}
              alt={`Nug image for ${strain.name}`}
              sizes={[208]}
              ratio="square"
            />
          </Link>
          <Link
            href={`/strains/${strain.slug}`}
            className="block font-bold text-md text-default underline mb-xs text-center line-clamp-1"
            onClick={() => {
              trackEvent("Homepage Strains", "Click", "Strain");
            }}
          >
            {strain.name}
          </Link>
          <p
            className="text-xs mb-xs text-center line-clamp-1"
            style={{ minHeight: 20 }}
          >
            {strain.subtitle}
          </p>
          {strain.averageRating && (strain.reviewCount || 0) > 0 ? (
            <Link
              href={`/strains/${strain.slug}#reviews`}
              onClick={() => {
                trackEvent("Homepage Strains", "Click", "Review count");
              }}
            >
              <StarRating
                rating={roundedStrainRating}
                ratingCount={strain.reviewCount}
                className={"flex text-xs justify-center mb-lg"}
                ratingCountClass={"text-green underline"}
              />
            </Link>
          ) : null}
          {strain.reviewCount !== undefined && strain.reviewCount === 0 && (
            <div className="text-xs mb-lg">No reviews submitted</div>
          )}
          <div
            className="flex justify-center items-center mb-lg"
            style={{ minHeight: 20 }}
          >
            <div className="text-xs bg-leafly-white rounded px-sm mr-md">
              {strain.phenotype}
            </div>
            {sortedTopCannabs &&
              sortedTopCannabs.length &&
              sortedTopCannabs?.[0]?.percentile50 &&
              sortedTopCannabs?.[0]?.percentile50 > 0 &&
              sortedTopCannabs.map((cannab: Cannabinoid, i: number) => {
                const rightMargin = i === 0 ? "mr-lg" : "";
                return (
                  <span
                    className={`text-xs rounded flex items-center ${rightMargin}`}
                    key={i}
                  >
                    <span className="mr-xs">
                      <IconSvg
                        height="24"
                        width="24"
                        filePath={`cannabinoids/${cannab.displayName.toLowerCase()}.svg`}
                      />
                    </span>
                    {cannab.displayName}{" "}
                    {cannab.percentile50 !== null &&
                    cannab.percentile50 >= 0 ? (
                      `${cannab.percentile50}%`
                    ) : (
                      <>&#8212;</>
                    )}
                  </span>
                );
              })}
          </div>
          {strain.hasAvailability ? (
            <TrackImpression
              category="Homepage Strains"
              label="available near you CTA"
            >
              <Button
                className="mx-auto px-xl h-[36px] w-[240px]"
                href={`/search/shop?shopCategory=strain&shopName=${strain.name}`}
                onClick={() => {
                  trackEvent(
                    "Homepage Strains",
                    "Click",
                    "available near you CTA",
                  );
                }}
              >
                available near you
              </Button>
            </TrackImpression>
          ) : (
            <TrackImpression category="Homepage Strains" label="learn more CTA">
              <Button
                className="mx-auto px-xl h-[36px] w-[240px]"
                href={`/strains/${strain.slug}`}
                onClick={() => {
                  trackEvent("Homepage Strains", "Click", "learn more CTA");
                }}
              >
                learn more
              </Button>
            </TrackImpression>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center">
          <FeaturedStrainCardPlaceholder />
        </div>
      )}

      {lineBreak && <hr className="border-light-grey mt-xl lg:hidden" />}
    </div>
  );
};

export default FeaturedStrainCard;
