import { useEffect, useState } from "react";
import cx from "classnames";

import { CampaignSlots } from "custom-types/Directus";
import { useSelector } from "redux/hooks";
import { getState } from "redux/selectors/location";
import { getTargetedData } from "utils/getTargetedData";

import CampaignCard from "./CampaignCard";

const HomePageCampaignSlots = ({
  campaignSlots,
  className,
}: {
  campaignSlots: CampaignSlots<string>[];
  className?: string;
}) => {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [campaignSlotItems, setCampaignSlotItems] =
    useState<CampaignSlots<string>[]>();

  const userState = useSelector(getState);

  const getCampaignSlots = () => {
    const campaignSlotItems = getTargetedData(
      campaignSlots,
      userState.toLowerCase(),
    );

    if (campaignSlotItems) {
      setCampaignSlotItems(campaignSlotItems.slice(0, 2));
    }

    setIsContentLoading(false);
  };

  useEffect(() => {
    getCampaignSlots();
  }, []);

  return campaignSlotItems && !isContentLoading ? (
    <div className={className}>
      <div className="container">
        <div className="row">
          {campaignSlotItems.length > 0 &&
            campaignSlotItems.map((campaignSlot, i) => (
              <CampaignCard
                className={cx("col", {
                  "mb-md md:mb-none": i !== campaignSlotItems.length - 1,
                  "md:col-1/2": campaignSlotItems.length === 2,
                  "md:col-1/3": campaignSlotItems.length === 3,
                })}
                {...campaignSlot}
                key={i}
              />
            ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default HomePageCampaignSlots;
