import { JsonLd } from "react-schemaorg";
import { WebSite } from "schema-dts";

import getFeaturedDispensaries from "api/requests/consumerApi/getFeaturedDispensaries";
import getMarqueeCreatives from "api/requests/consumerApi/getMarqueeCreatives";
import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getCampaignSlots from "api/requests/getCampaignSlots";
import getNewsletterSection from "api/requests/getNewsletterSection";
import getNewsSections from "api/requests/getNewsSections";
import getPopularCities from "api/requests/getPopularCities";
import getProductCategories from "api/requests/getProductCategories";
import { getUserLocationData } from "api/requests/getUserLocation";
import getValueProps from "api/requests/getValueProps";
import { Action, Category } from "constants/events";
import { AdMarqueePageLocation, Creative } from "custom-types/AdMarquee";
import {
  CampaignSlots,
  HomePageNewsSection,
  NewsletterSection,
  PopularCities as PopularCitiesType,
  ProductCategories,
  ValueProps as ValuePropsType,
} from "custom-types/Directus";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import { FeaturedDispensary } from "custom-types/FeaturedDispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import { determineOrderMethod } from "utils/determineOrderMethod";
import { getMerchId } from "utils/eventTracking";
import { getBackfilledFeaturedDispensaries } from "utils/getBackfilledFeaturedDispensaries";
import { localizeText } from "utils/localizeText";
import { getCountryCodeHeader } from "utils/requestContext";

import AdMarqueeCarousel from "components/AdMarqueeCarousel";
import DealsTakeover from "components/DealsTakeover";
import DispensariesCarousel from "components/DispensariesCarousel";
import DownloadAppCTA from "components/DownloadAppCTA";
import FeaturedBrands from "components/FeaturedBrands";
import FeaturedDispensariesSection from "components/FeaturedDispensariesSection";
import FeaturedStrains from "components/FeaturedStrains";
import HomePageCampaignSlots from "components/HomePageCampaignSlots";
import HomePageNewsCta from "components/HomePageNewsCta";
import HomePageProductCategories from "components/HomePageProductCategories";
import MetaTags from "components/MetaTags";
import Newsletter from "components/Newsletter";
import PopularCities from "components/PopularCities";
import SocialImpact from "components/SocialImpact";
import ValueProps from "components/ValueProps";

type HomePageProps = {
  campaignSlots: CampaignSlots<string | null>[];
  isDelta9Allowed: boolean;
  newsletterSections: NewsletterSection[];
  newsSections: HomePageNewsSection<string>[];
  popularCities?: PopularCitiesType;
  productCategories: ProductCategories<string | null>[];
  valueProps?: ValuePropsType;
  creatives: Creative[];
  featuredDispensariesWithBackfill: FeaturedDispensary[];
};

const HomePage: React.FC<HomePageProps> = ({
  campaignSlots,
  isDelta9Allowed,
  newsletterSections,
  newsSections,
  popularCities,
  productCategories,
  valueProps,
  creatives,
  featuredDispensariesWithBackfill,
}) => {
  const countryCode = useDomainCountryCode();

  const { publishEvent } = useEventTracker();

  const trackCardImpression = (slot: number, dispensary: DispensaryV3) => {
    const eventLabel = determineOrderMethod(
      !!dispensary.flags.includes("pickup"),
      !!dispensary.flags.includes("delivery"),
      dispensary.tier,
      dispensary.flags,
    );

    const merchandisingCampaignId = getMerchId(dispensary, "platinumplacement");

    publishEvent({
      action: Action.impression,
      category: Category.dispensaryCard,
      dispensaryId: dispensary.id,
      label: `slot #${slot + 1}: ${eventLabel}`,
      merchandisingCampaignId,
    });
  };

  const schemaTargetUrl =
    countryCode === "CA" ? "https://www.leafly.ca/" : "https://www.leafly.com/";

  return (
    <>
      {/* here for SEO purposes! please dont delete */}
      <h1 className="hidden">Leafly</h1>
      <JsonLd<WebSite>
        item={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Leafly",
          potentialAction: {
            "@type": "SearchAction",
            // @ts-expect-error -- This attribute is documented (https://schema.org/docs/actions.html#part-4), but not specified in the schema-dts package
            "query-input": "required name=search_term_string",
            target: `${schemaTargetUrl}shop?q={search_term_string}`,
          },
          url: schemaTargetUrl,
        }}
      />
      <MetaTags
        title="Find, order, and learn about weed | Leafly"
        description="Leafly is the leading destination to learn about, find, and order cannabis. Learn more about types of weed strains and discover marijuana dispensaries near you."
      />
      <div className="container mt-xl mb-lg">
        <AdMarqueeCarousel
          pageType={AdMarqueePageLocation.HOME_PAGE}
          creatives={creatives}
        />
      </div>

      <FeaturedDispensariesSection
        className="my-xxl"
        featuredDispensaries={featuredDispensariesWithBackfill}
        isDelta9Allowed={isDelta9Allowed}
      />

      {productCategories.length > 0 && (
        <HomePageProductCategories
          productCategories={productCategories}
          className="border-b border-t border-light-grey pt-xl pb-lg"
        />
      )}
      <FeaturedBrands className="py-xxl" />
      <DealsTakeover
        className="mb-xxl"
        gaCategory="Homepage Deals"
        signupSource="homepage_deals"
      />
      <DispensariesCarousel
        title={`Shop ${localizeText("dispensaries", countryCode)} near you`}
        subtitle="Showing you stores near"
        linkText={`See all ${localizeText("dispensaries", countryCode)}`}
        page="home"
        changeLocation={true}
        link="/dispensaries"
        className="pb-xxl"
        trackCardImpression={trackCardImpression}
      />
      <FeaturedStrains className="py-xxl" />
      {valueProps && <ValueProps {...valueProps} className="py-xxl" />}
      <DownloadAppCTA
        eventCategory="Homepage App Feature"
        title="The Leafly app is where it’s at"
        subtitle="Over 300,000 reviews on App Store and Google Play"
        description="Your direct line to the world’s largest strain library, over
                    a million strain reviews, award-winning articles, and of
                    course, shopping weed near you."
        utmCampaign="Home"
      />
      <HomePageCampaignSlots
        campaignSlots={campaignSlots}
        className="border-b border-light-grey py-xxl"
      />
      <HomePageNewsCta
        newsSections={newsSections}
        className="border-b border-light-grey py-xxl"
      />
      <SocialImpact className="border-b border-light-grey py-xxl" />
      <Newsletter
        newsletterSections={newsletterSections}
        className="border-b border-light-grey py-xxl"
      />
      <PopularCities
        cities={popularCities?.cities}
        className="pt-xxl pb-section"
      />
    </>
  );
};

export const getServerSideProps = createGetServerSideProps<HomePageProps>(
  async (context) => {
    const countryCode = getCountryCodeHeader(context.req.headers);

    const userLocation = await getUserLocationData(countryCode, context);

    const [
      campaignSlots,
      newsletterSections,
      newsSections,
      popularCities,
      productCategories,
      valueProps,
      creatives,
      featuredDispensaries,
      complianceRuleset,
    ] = await Promise.all([
      getCampaignSlots(countryCode),
      getNewsletterSection(countryCode),
      getNewsSections(countryCode),
      getPopularCities(countryCode),
      getProductCategories(countryCode),
      getValueProps(countryCode),
      getMarqueeCreatives(AdMarqueePageLocation.HOME_PAGE, {
        lat:
          userLocation.coordinates?.lat || userLocation.coordinates?.latitude,
        lon:
          userLocation.coordinates?.lon ||
          userLocation.coordinates?.longitude ||
          userLocation.coordinates?.lng,
      }),
      getFeaturedDispensaries(
        {
          lat:
            userLocation.coordinates?.lat || userLocation.coordinates?.latitude,
          lon:
            userLocation.coordinates?.lon ||
            userLocation.coordinates?.longitude,
        },
        countryCode,
      ),
      getRegionComplianceRules(userLocation.state_code, countryCode),
    ]);

    const featuredDispensariesWithBackfill =
      await getBackfilledFeaturedDispensaries(
        featuredDispensaries,
        userLocation,
      );

    const isDelta9Allowed =
      Boolean(complianceRuleset?.rules?.delta9Allowed) || false;

    return {
      props: {
        campaignSlots,
        creatives,
        featuredDispensariesWithBackfill,
        isDelta9Allowed,
        newsSections,
        newsletterSections,
        popularCities,
        productCategories,
        valueProps,
      },
    };
  },
);

export default HomePage;
