import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import classNames from "classnames";

import publicConfig from "config/public";

import StarSolidIcon from "components/Icons/star_solid.svg";
import Image from "components/Image";
import TrackImpression from "components/TrackImpression";

type Props = {
  className?: string;
  description?: string;
  eventCategory: string;
  subtitle: string;
  title: string;
  utmCampaign: string;
};

const {
  imgix: { publicUrl },
} = publicConfig;

const DownloadAppCTA = ({
  className,
  description,
  eventCategory,
  subtitle,
  title,
  utmCampaign,
}: Props) => {
  return (
    <TrackImpression category={eventCategory} label="download prompt">
      <div className={classNames("gradient pt-xl", className)}>
        <style jsx>{`
          .gradient {
            background-image: linear-gradient(to right, #fff4e3, #ffedd2);
          }
          @media only screen and (max-width: 767px) {
            .gradient {
              background-image: linear-gradient(#fff4e3, #ffedd2);
            }
          }
        `}</style>
        <div className="pt-lg md:px-lg md:pb-lg">
          <div className="container">
            <div className="row">
              <div className="md:col-1/2 flex items-center justify-center text-center md:text-left">
                <div>
                  <h3 className="pb-sm">{title}</h3>
                  {Array(5)
                    .fill(0)
                    .map((_, idx) => (
                      <div className="inline-block" key={`star-${idx + 1}`}>
                        <StarSolidIcon height="20" width="20" />
                      </div>
                    ))}
                  <div className="text-xs">{subtitle}</div>
                  {description && (
                    <p className="text-sm pt-sm">{description}</p>
                  )}
                  <div className="inline-block pt-lg -ml-md md:ml-none">
                    <a
                      href={`https://apps.apple.com/app/apple-store/id416456429?pt=452354&ct=Leafly%20${utmCampaign}&mt=8`}
                      onClick={() => {
                        trackEvent(eventCategory, "Click", "download from iOS");
                      }}
                    >
                      <Image
                        src={`${publicUrl}/web-home/app-store.png`}
                        sizes={[154]}
                        alt="Apple app store logo"
                      />
                    </a>
                  </div>
                  <div className="inline-block pl-xs md:pl-lg -mr-md md:mr-none">
                    <a
                      href={`https://play.google.com/store/apps/details?id=leafly.android&referrer=utm_source%3Dleafly%26utm_medium%3Dreferral%26utm_campaign%3D${utmCampaign?.toLowerCase()}`}
                      onClick={() => {
                        trackEvent(
                          eventCategory,
                          "Click",
                          "download from android",
                        );
                      }}
                    >
                      <Image
                        src={`${publicUrl}/web-home/google-play.png`}
                        sizes={[170]}
                        alt="Google play store logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="md:col-1/2 hidden md:block">
                <div aria-label="Photo of mobile phone displaying Leafly strains">
                  <Image
                    src={`${publicUrl}/web-home/app-desktop.png`}
                    sizes={[500]}
                    alt="Photo of mobile phone displaying Leafly strains"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <div aria-label="Photo of mobile phone displaying Leafly strains">
            <Image
              src={`${publicUrl}/web-home/app-mobile.png`}
              sizes={[500]}
              alt="Photo of mobile phone displaying Leafly strains"
            />
          </div>
        </div>
      </div>
    </TrackImpression>
  );
};

export default DownloadAppCTA;
