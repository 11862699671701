import React, { useEffect, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import addEmailSignup from "api/requests/addEmailSignup";
import { NewsletterSection } from "custom-types/Directus";
import { useSelector } from "redux/hooks";
import { getLocationState } from "redux/selectors/location";
import { getTargetedData } from "utils/getTargetedData";

import EmailSignup from "components/botanic/EmailSignup";
import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  newsletterSections: NewsletterSection[];
  className?: string;
};
const Newsletter = ({ newsletterSections, className }: Props) => {
  const userLocation = useSelector(getLocationState);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [newsletterSection, setNewsletterSection] =
    useState<NewsletterSection>();
  const [isLoading, setIsLoading] = useState(true);

  const emailSignup = async (email: string) => {
    trackEvent("Homepage Email", "Click", "Email submit");

    await addEmailSignup(email, userLocation, "homepage_footer");

    setIsEmailSubmitted(true);
  };

  useEffect(() => {
    const newsletterSectionItems = getTargetedData(
      newsletterSections,
      userLocation.state.toLowerCase(),
    );

    setNewsletterSection(newsletterSectionItems?.[0]);
    setIsLoading(false);
  }, []);

  const title = newsletterSection?.title || "Stay in touch";
  const subtitle =
    newsletterSection?.subtitle ||
    "Get good reads, local deals, and strain spotlights delivered right to your inbox.";

  return (
    <div className={className}>
      <div className="container">
        <div className="md:col-2/3 lg:col-1/2 md:mx-auto">
          <div className="font-extrabold text-lg text-center">
            {isLoading ? (
              <Placeholder className="mx-auto" width="60%" height="30">
                <Rect width="100%" height="100%" />
              </Placeholder>
            ) : (
              title
            )}
          </div>
          <div className="text-sm mt-sm text-center">
            {isLoading ? (
              <Placeholder className="mb-md" width="100%" height="20">
                <Rect width="100%" height="100%" />
              </Placeholder>
            ) : (
              subtitle
            )}
          </div>
          {isEmailSubmitted ? (
            <div className="mt-md text-center text-green">
              Thanks for signing up!
            </div>
          ) : (
            <div className="md:px-section">
              <EmailSignup
                onSubmit={emailSignup}
                id="newsletter-email-signup"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
