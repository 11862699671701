import React, { useEffect, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import { Action, Category } from "constants/events";
import { ProductCategories } from "custom-types/Directus";
import useDomain from "hooks/useDomain";
import { useSelector } from "redux/hooks";
import { getState } from "redux/selectors/location";
import { getTargetedData } from "utils/getTargetedData";

import ProductCategoryCard from "components/botanic/ProductCategoryCard";
import ProductCategoryCardPlaceholder from "components/botanic/ProductCategoryCard/ProductCategoryCardPlaceholder";
import TrackImpression from "components/TrackImpression";

type Props = {
  className?: string;
  productCategories?: ProductCategories<string | null>[];
};

const HomePageProductCategories: React.FunctionComponent<Props> = ({
  className = "",
  productCategories = [],
}: Props) => {
  const baseUrl = useDomain();
  const orderedProductCategories = productCategories.sort(
    (a, b) => (a.order || 0) - (b.order || 0),
  );

  const [productCategoryList, setProductCategoryList] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const userState = useSelector(getState).toLocaleLowerCase();

  const gatherProductCategories = () => {
    try {
      const gatheredProductCategories = getTargetedData(
        orderedProductCategories,
        userState,
      );

      setProductCategoryList(gatheredProductCategories);
    } catch (err) {
      return [];
    }
  };

  useEffect(() => {
    gatherProductCategories();
  }, []);

  return (
    <div className={className}>
      <h2 className="heading--l container pb-sm">Shop by category</h2>
      <div className="container flex items-center lg:justify-between overflow-auto lg:overflow-hidden mx-none md:mx-auto pb-sm">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate */}
        {productCategoryList.map((category: any, i: any) =>
          category.name ? (
            <TrackImpression
              category="Homepage Products"
              label={category.name}
              className="mx-sm"
              key={`${i + 1}-${category.name}`}
              style={{ minWidth: 80, width: 80 }}
            >
              <ProductCategoryCard
                image={category.image}
                title={category.name}
                titleSize="xs"
                titleClassName="underline"
                url={`${baseUrl}${category.url}`}
                disableLazyLoad={true}
                sizes={[80]}
                onClick={() => {
                  trackEvent(
                    Category.productCategoryCoinFilter,
                    Action.click,
                    category.name.toLowerCase().replace(/-/g, ""),
                  );
                }}
              />
            </TrackImpression>
          ) : (
            <ProductCategoryCardPlaceholder
              key={i}
              className="mx-sm"
              style={{ minWidth: 80, width: 80 }}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default HomePageProductCategories;
